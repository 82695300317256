<template>
    <default-layout class="contactPage">
        <i class="far fa-paper-plane"></i>
        <h1>Get in touch</h1>
        <p>
            Want to get in touch? We'd love to hear from you.
        </p>
        <a
            class="button is-info is-medium"
            href="mailto:hello@sessionscoop.com"
        >
            hello@sessionscoop.com
        </a>
    </default-layout>
</template>

<script>
export default {
    name: 'Contact',
}
</script>
